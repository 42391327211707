.card {
    height: 469px;
    border-radius: 16px;
    border: 1px solid var(--border-color);
    cursor: pointer;
    transition: all ease .3s;
}

.card:hover {
    opacity: .5;
    transform: scale(.95);
}

.card-header img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card-apartment {
    color: var(--text-color);
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0%;
    margin-bottom: 8px;
    margin-top: 8px;
}

.card-address {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0%;
    color: var(--secondary-text-color);
}

.card-price {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0%;
    color: var(--main-color);
}

.card-footer {
    padding: 24px;
}

.card-price .month-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0%;
    color: var(--secondary-text-color);
}

.card-footer-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-id {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0%;
    color: var(--secondary-text-color);
}

.card-line {
    width: 100%;
    height: 1px;
    background: var(--border-color);
    margin-top: 24px;
    margin-bottom: 24px;
}

.card-info-box {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0%;
    color: var(--text-color);
}

.card-footer-bottom {
    display: flex;
    gap: 16px;
    align-items: center;
}

.card-header img {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.card-header {
    height: 240px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center ;
}

.main-dots div{
    min-width: 10px;
    min-height: 10px;
    border-radius: 50%;
    background-color: var(--border-color);
}

.main-dots {
    bottom: 12px;
    position: absolute;
    display: flex;
    justify-content: center;
    gap: 5px;
}

@media screen and (max-width: 390px) {
    .card {
        width: 100%;
    }
}

.squaremeter {
    gap: 6px;
}