.burger-menu {
    width: 100%;
    height: 100%;
    position: fixed;
    left: -100%;
    top: 0;
    z-index: 99999;
    background: #fff;
    padding: 16px;
    transition: all ease-in-out .3s;
}
.burger-menu button {
    width: 100%;
    display: flex;
    justify-content: center;
}
.burger-menu-btn {
    border-top: 1px solid #F2F4F7;
    padding-top: 24px;
    position: absolute;
    width: 100%;
    left: 0;
    padding-left: 16px;
    padding-right: 16px;
    bottom: 24px;
}
.burger-menu-header {
    display: flex;
    position: absolute;
    width: 100%;
    left: 0;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    justify-content: space-between;
    border-bottom: 1px solid #F2F4F7;
}
.burger-menu-links {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 75px;
}
.burger-menu-links a{
    padding-top: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding-bottom: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0%;
    color: #101828;
}
.burger-menu.active {
    left: 0px;
    position: fixed;
}