.all-checks {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-top: 24px;
}
.all-checks > div {
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0%;
    color: var(--secondary-text-color);
}
.amenities{
    padding-top: 32px;
}
.location {
    padding-top: 32px;
}
.location iframe{
    margin-top: 24px;
}
.item-headers {
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    letter-spacing: 0%;
    color: #101828;
    margin-bottom: 20px;
    /* margin-top: 32px; */
}
.item-info-section {
    width: 100%;
    max-width: 720px;
}
.item-info-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0%;
    color: var(--secondary-text-color);
}
.item-images {
    margin-bottom: 64px;
}
.item-page-data {
    display: flex;
    justify-content: space-between;
}
@media screen and (max-width: 650px) {
    .item-page-data {
        flex-direction: column-reverse;
    }
    .item-images {
        margin-bottom: 32px;
    }
}