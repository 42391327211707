.contact-us-section {
    margin-top: 64px;
    display: flex;
    justify-content: space-between;
}

.support-card {
    display: flex;
}

.support-card div {
    padding: 15px;
    margin: 5px 0 0 0; 
}

.card-header {
    font-size: 20px;
    font-weight: 600;
}
.support-card .card-header {
    height: auto;
    display: block;
}
.card-text {
    padding: 5px 0;
    margin-top: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0%;
    color: var(--secondary-text-color);
}

.example {
    padding: 10px 0;
    font-weight: 600;
    color: var(--main-color);
}

.contact-us-section iframe {
    border: none;
}

@media screen and (max-width: 1024px) {
    .contact-us-section {
        display: inline;
        
    }
    .contact-us-section iframe {
        width: 100%;
        border-radius: 20px;
        border: none;
        display: block;
        justify-content: center;
    }
    .support-card {
        margin: 15px 0;
        display: flex;
        justify-content: left;
    }
}