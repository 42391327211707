.navigation {
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: #FCFCFD;
  border-radius: 8px;
  gap: 8px;
  justify-content: space-between;
  min-width: 210px;
  width: auto;
  margin: 32px 0 0 0;
}
.navigation > span {
  color: #475467;
}