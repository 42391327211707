.filters-bar {
    width: 375px;
    margin: 24px 0 0 0;
    background-color: #F9FAFB;
    border-radius: 8px;
    border: var(--border-color) solid 1px;
}

.filter-params {
    padding: 24px 16px;
    border-top: 1px solid var(--border-color);
}

.filter-params div p {
    margin: 0 0 8px 0;
    font-weight: 500;
    font-size: 14px;
    color: var(--secondary-text-color);
}

.filter-params > div {
    padding: 8px;
    padding-left: 0px;
    padding-right: 0px;
}

#area {
    margin: 0;
}

.filter-params div span {
    font-size: 14px;
    font-weight: 500;
}

.sort-filter {
    width: 375px;
    height: 72px;
    border: var(--border-color) solid 1px;
    border-radius: 8px 0 0 8px;
    display: flex;
    justify-content: center;
    padding: 16px 0;
}

.sort-filter span {
    font-weight: 600;
    font-size: 18px;
    padding: 8px 0;
}
.selected-several {
    margin: 0;
    width: 100px;
    height: 24px;
    border: 1px solid var(--border-color);
    align-items: center;
    gap: 6px;
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    justify-content: center ;
}

.custom-select {
    background-color: #fff;
}

.select-several {
    padding: 8px 12px;
    display: flex;
    justify-content: left;
    border: var(--border-color) solid 1px;
    background-color: #fff;
    border-radius: 8px;
    height: 40px;
    gap: 6px;
}

.selected-close {
    height: 16px;
    width: 16px;
    margin: 2px;
}

.number-select {
    display: flex;
    gap: 8px;
}

.number-select p {
    padding: 5px 14px;
    border: 1px solid var(--border-color);
    border-radius: 16px;
    margin-right: 8px;
}

.minmax input {
    border-radius: 8px;
    border: none;
    background-color: #F9FAFB;
    padding: 10px 14px;
    border: 1px solid var(--border-color);
    width: 155px;
}

.minmax-togheter {
    display: flex;
    align-items: center;
}

.transaction-type div {
    display: block;
    padding: 8px 0;
}

.property-type input {
    margin: 16px 0;
}

.transaction-type div span {
    padding: 0 8px;
}

.property-type span {
    padding: 0 8px;
}

.selections-item{
    padding: 4px 0;
    align-items: center;
}

.prop-text {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 92px;
}

.prop-text-p {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px;
}

.filter-prop-text {
    margin: 16px 0 24px 0;
}

.selections-item span {
    padding: 0 8px;
}

.navigation-and-filters {
    flex-direction: column;
}

.search-filter-mobile {
    display: none;
}

.prop-text {
    display: flex;
}

input[type="text"] {
    background-color: #fff;
}

@media screen and (max-width: 800px) {
    .prop-text {
        margin-top: 12px;
    }
    .filters-bar {
        display: none;
    }
    .filter-container {
        flex-direction: column;
    }
    .search-filter-mobile {
        display: flex;
        gap: 16px;
        padding: 16px 0;
    }
    .search-mob {
        gap: 8px;
        width: 100%;
        justify-content: left;
    }
    .mob {
        align-items: center;
        display: flex;
        padding: 10px 14px;
        border: 1px solid var(--border-color);
        border-radius: 8px;
    }
}