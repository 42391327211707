.sort-and-filter {
    display: flex;
    flex-direction: column;
    width: 688px;
    padding: 16px;
}

.sort-filter {
    display: flex;
    padding: 0;
    border: 0;
    height: 40px;
    margin: 0;
    justify-content: space-between;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
}

.two-in-one {
    display: flex;
    gap: 16px;
}

.custom-select-sort {
    width: 100%;
}

.select-several-sort {
    height: 44px;
    width: 100%;
}

.area-select-p {
    padding: 0 0 3px 0 ;
}