.wrapper {
    background: url(../../assets/wrapper_bg.png);
    width: 100%;
    height: 728px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.wrapper >div {
    display: flex;
    flex-direction: column;
    /* text-align: center; */
}
.wrapper_about {
    color: #EAECF0;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0%;
    text-align: center;
}
.wrapper_about_span {
    font-weight: 600;
    font-size: 72px;
    line-height: 90px;
    letter-spacing: -2%;
    text-align: center;
    max-width: 760px;
    color: #FFFFFF;
}
.wrapper_about_span_small {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0%;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 48px;
    color: #EAECF0;
}

@media screen and (max-width: 1024px) {
    .wrapper {
        height: 1000px;
    }
    
}

@media screen and (max-width: 480px) {
    .wrapper {
        height: 1150px;
        align-items: center;
        justify-content: start;
        padding-top: 72px;
        height: auto;
        padding-bottom: 40px;
    }
    .wrapper_about_span {
        text-align: start;
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -2%;
        margin-top: 12px;
    }
    .wrapper_about {
        text-align: start;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0%;
    }
    .wrapper_about_span_small {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0%;
        text-align: start;
        margin-top: 16px;
        margin-bottom: 8px;
    }
    .wrapper_about_panel {
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 40px;
    }
}
@media screen and (max-width: 356px) {
    .wrapper {
        height: 1300px;
    }
    
}