.info-boxes {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    margin-top: 64px;
    gap: 24px;
}
.why {
    margin-top: 96px;
    margin-bottom: 96px;
}
.info-box {
    min-height: 246px;
    background: var(--box-color);
    border-radius: 8px;
    padding: 24px;
}
.info-box > div {
    max-width: 238px;
}
.box-header {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0%;
    color: #1D2939;
    margin-bottom: 8px;
}
.box-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0%;
    color: var(--secondary-text-color);
}