.district1 {
    border: 1px #EAECF0 solid;
    border-radius: 8px;
    background: #ffffff;
    padding: 16px 14px;
    width: 343px;
    height: 352px;
}

.checks div input {
    margin-right: 12px;
}

.checks div {
    margin-bottom: 22px;
}

.buttons {
    padding: 12px 16px;
    height: 68px;
    display: flex;
    gap: 12px;
    justify-content: center;
    border-top: var(--border-color) solid 1px;
}

.reset {
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0);
    color: var(--text-color);
}

.apply {
    display: flex;
    justify-content: center;
}