footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 64px;
    margin-bottom: 64px;
}
footer > div > div {
    display: flex;
    align-items: center;
}
footer > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.footer-social-links {
    display: flex;
    gap: 24px;
}
#footer-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0%;
    color: #667085;
}
.footer-line {
    width: 100%;
    margin-bottom: 32px;
    margin-top: 64px;
    height: 1px;
    background: #EAECF0;
}
.footer-links a {
    font-style: none;
    color: var(--text-color); 
}
.social-media {
    cursor: pointer;
}
.lang-footer {
    display: flex;
    align-items: center;
    gap: 8px;
}
.social-media:hover {
    transition: 0.2s;
    transform: scale(1.1);
}
.footer-links {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    margin-left: 40px;
    color: var(--text-color);
}

@media  screen and (max-width: 640px) {
    footer .container {
        flex-direction: column;
        display: block;
    }
    .footer-content {
        flex-direction: column;
        gap: 48px;
    }
    .footer-logo {
        flex-direction: column;
        gap: 32px;
    }
    .footer-contact {
        flex-direction: column;
        gap: 16px;
        width: 100%;
    }
    .call{
        margin: 0;  
    }
    .footer-bottom {
        flex-direction: column;
        gap: 16px;
    }
    .footer-links a {
        font-style: none;
        color: var(--text-color); 
    }
}