@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
:root {
  --main-color: #7F56D9;
  --border-color: #F2F4F7;
  --text-color: #1D2939;
  --secondary-text-color: #475467;
  --box-color: #FCFCFD;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
    font-family: "Inter", sans-serif;
}
body {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}
#root {
  width: 100%;
}
a {
  text-decoration: none;
}
.btn {
  border: none;
  color: #fff;
  background: var(--main-color);
  border-radius: 8px;
  height: 44px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 1.25%;
  display: flex;
  align-items: center;
  gap: 8px;
}
.cards {
  width: 100%;
  display: grid;
  grid-row-gap: 48px;
  grid-column-gap: 32px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
}
.section-header {
  margin-bottom: 64px;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -2%;
  text-align: center;
  color: #101828;
}
.aprt-section {
  margin-top: 96px;
}
.block-header {
font-weight: 600;
font-size: 36px;
line-height: 44px;
letter-spacing: -2%;
color: #101828;
}
.about-us {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0%;
  color: var(--secondary-text-color);
  max-width: 760px;
}
.container {
  padding-right: 112px;
  padding-left: 112px;
}
.section-header-small {
  text-align: center;
  color: var(--secondary-text-color);
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0%;
}
@media screen and (max-width: 1020px){
  .container {
    padding-right: 16px;
    padding-left: 16px;
  }
}