.filters {
    width: 100%;
    max-width: 1132px;
    background: #fff;
    border-radius: 8px;
    display: flex;
    align-items: end;
    gap: 16px;
    padding-left: 16px;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-right: 16px;
}
.filters-panel {
    max-width: 1132px;
    width: 100%;
}
.custom-select-header1 {
    color: var(--secondary-text-color);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0%;
    margin-bottom: 6px;
}
@media screen and (max-width: 1024px) {
    .filters {
        flex-direction: column;
        padding-left: 16px;
        padding-right: 16px;
        align-items: start;
    }
    .filters-panel {
        padding: 24px 16px;
        height: 392px;
    }
    .filters div {
        width: 100%;   
    }
    #line {
        width: 16px;
    }
    .search {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .custom-select-header1 {
        display: none;
    }
    .prices input{
        display: none;
    }
    .line {
        display: none;
    }
    .filters button {
        justify-content: center;
    }
    .advanced-buttons {
        display: flex;
        justify-content: center;
    }
}
.filters-btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    padding: 4px;
    border: 1px solid var(--border-color);
    max-width: 311px;
    margin-bottom: 8px;
    background: #F9FAFB;
    border-radius: 8px;
}
.filters-btn {
    border: none;
    height: 36px;
    cursor: pointer;
    border-radius: 6px;
    background: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0%;
    color: var(--secondary-text-color);
}
.filters-btn.active {
    color: var(--main-color);
    box-shadow: 0px 1px 2px 0px #1018280F;
    box-shadow: 0px 1px 3px 0px #1018281A;
}
.custom-select-header {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0%;
    margin-bottom: 6px;
    color: var(--secondary-text-color);
}
.custom-select {
    height: 44px;
    border: 1px solid var(--border-color);
    width: 100%;
    min-width: 232px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 14px;
    padding-right: 14px;
}
.price-input {
    border: 1px solid var(--border-color);
    height: 44px;
    border-radius: 8px;
    outline: none;
    width: 100%;
    max-width: 100px;
    padding-left: 14px;
    padding-right: 14px;
    color: var(--text-color);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0%;
}
.prices {
    display: flex;
    gap: 8px;
    align-items: center;
}
.line {
    background: var(--secondary-text-color);
    width: 16px;
    height: 1px;
}
.search {
    padding-left: 34.5px;
    padding-right: 34.5px;
}
.open-advanced {
    height: 44px;
    border: none;
    background: none;
    font-weight: 600;
    width: 192px;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: var(--secondary-text-color);
}
@media screen and (max-width: 500px){
    .filters-btns {
        max-width: 100%;
    }
}