.search-mob {
    gap: 8px;
    width: 344px;
    justify-content: left;
}

.down-mob {
    gap: 32px;
    width: 232px;
}

.down-mob1 {
    gap: 32px;
    width: 232px;
}

.filter-mob {
    padding-left: 25px;
    padding-right: 25px;
    height: 44px;
    gap: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.mob {
    align-items: center;
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    padding: 10px 14px;
    border: 1px solid var(--border-color);
    border-radius: 8px; 
}

.search-bar {
    display: flex;
    padding: 16px 0;
    gap: 16px;
}

.srch-btn {
    width: 148px;
}

.search-mob span {
    color: var(--secondary-text-color);
}

.search-filter-mobile {
    display: none;
}

.navigation {
    margin: 24px 0 0 0 ;
}

@media screen and (max-width: 1178px) {
    .search-bar {
        display: none;
    }
    .search-filter-mobile {
        display: flex;
        gap: 16px;
        padding: 16px 0;
    }
    .filter-mob {
        gap: 0;
        width: 44px;
        height: 44px;
    }
    .search-mob {
        gap: 8px;
        width: 100%;
        justify-content: left;
    }
}