.item-params {
    height: 464px;
    width: 384px;
    padding: 32px;
    background: #F9FAFB;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    border-top: 4px solid var(--main-color);
}

@media screen and (max-width: 650px) {
    .item-params {
        border-top: none;
        background: none;
        width: 100%;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        padding: 0;
    }
}

.appartment-type {
    font-size: 24px;
    font-weight: 600;
    margin: 12px 0;
}

.item-param-street {
    font-size: 16px;
    margin: 10px 0;
    color: #475467;
}

.home-params-items {
    padding: 10px 0;
    display: flex;
}

.home-params-items span {
    padding: 3px 5px;
}

.home-params {
    padding: 10px 0;
}



.call-button {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.amount {
    font-size: 24px;
    font-weight: 600;
    color: var(--main-color);
}

.apt-type-amount {
    padding: 10px 0;
}