header {
    height: 80px;
    width: 100%;
    z-index: 1;
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--border-color);
    position: sticky;
    background: #fff;
}
.header-links {
    display: flex;
    gap: 32px;
    margin-left: 40px;
}
.header-links a {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0%;
}
header > div > div {
    display: flex;
    align-items: center;
}
header > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.call {
    min-width: 118px;
    display: flex;
    justify-content: center;
    margin-left: 12px;
    padding-left: 13px;
    padding-right: 13px;
}
.lang {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--text-color);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0%;
    cursor: pointer;
    padding-left: 18px;
    padding-right: 18px;
}
.text-light {
    color: #fff;
}
.text-dark {
    color: var(--text-color);
}
#burger-menu-icon {
    display: none;
}
@media screen and (max-width: 720px){
    header .header-links,
    header > div > div {
        display: none;
    }
    #burger-menu-icon {
        display: block;
    }
}